'use client';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useLogOut } from '@/src/hooks/log-out';
export function AlreadyAuthedCardInner({
  displayName,
  title
}: {
  displayName: string;
  title: string;
}) {
  const logOut = useLogOut();
  return <Card className='[view-transition-name:auth-card]' data-sentry-element="Card" data-sentry-component="AlreadyAuthedCardInner" data-sentry-source-file="already-authed-card.client.tsx">
			<CardHeader data-sentry-element="CardHeader" data-sentry-source-file="already-authed-card.client.tsx">
				<CardTitle className='[view-transition-name:auth-card-title]' data-sentry-element="CardTitle" data-sentry-source-file="already-authed-card.client.tsx">{title}</CardTitle>
			</CardHeader>

			<CardContent data-sentry-element="CardContent" data-sentry-source-file="already-authed-card.client.tsx">
				<CardDescription className='[view-transition-name:auth-card-description]' data-sentry-element="CardDescription" data-sentry-source-file="already-authed-card.client.tsx">
					You're already signed in as {displayName}.
				</CardDescription>
			</CardContent>

			<CardFooter className='justify-end' data-sentry-element="CardFooter" data-sentry-source-file="already-authed-card.client.tsx">
				<Button disabled={logOut.isPending} onClick={logOut.logOut} className='[view-transition-name:auth-card-button]' data-sentry-element="Button" data-sentry-source-file="already-authed-card.client.tsx">
					Log out
				</Button>
			</CardFooter>
		</Card>;
}